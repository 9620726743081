import React from 'react';

import './UpcomingShows.css';
import { Item } from '../../components';
import { images, data } from '../../constants';

/*
    <div className="app__shows-calendar">
        <div className="app__shows-calendar-left  flex__center_calendar">
            <h1 className='headtext__cormorant-show-header'>Pensacola, FL</h1>
            <div className="app__shows-calendar-perflocation">Pensacola Little Theatre</div>
            <div className="app__shows_calendar_items">
                {data.feb13_2023Shows.map((show, index) => (
                    <Item key={show.title + index} location={show.location} perflocation={show.perflocation} date={show.date} time={show.time} />
                ))}
                <div id="order-ticketscontainer">
                    <button className="order-tickets_show"><a href={data.feb13_2023Tickets}>Order Tickets</a></button>
                </div>
            </div>
        </div>
    </div>

*/

/*

<table className="app__shows-calendar">
                <tbody className="app__shows-event-container">
                    <tr className="app__shows-event">
                        <td className="app__shows-location">
                            <div className="app__show-location">
                                <h1 className='app__show-locname'>San Diego, CA</h1>
                            </div>
                            <div className="app__show-theatre">
                                <p className="app__show-theatname">Atlanta Black Theatre Festival</p>
                                <p className="app__show-theatname-sub">Southwest Arts Center - Main Stage</p>
                            </div>
                        </td>
                        <td className="app__shows-dates">
                            <p>August 31st, 4:00pm EDT</p>
                        </td>
                        <td className="app__shows-ticket-container">
                            <a className="app__shows-ticket" href="https://abtf.ludus.com/index.php" target="_blank" rel="noopener noreferrer">Order Tickets</a>
                        </td>
                    </tr>
                </tbody>
            </table> 

*/

const UpcomingShows = () => (
    <div className="app__bg app__shows" id="shows" var={{padding:"2rem"}}>
        <div className="app__shows-title flex__center">
            <h1 className="headtext__cormorant">Upcoming Shows</h1>
        </div>
        <div>
            <table className="app__shows-calendar">
                <tbody className="app__shows-event-container">
                    <tr className="app__shows-event">
                        <td className="app__shows-location">
                            <div className="app__show-location">
                                <h1 className='app__show-locname'>San Diego, CA</h1>
                            </div>
                            <div className="app__show-theatre">
                                <p className="app__show-theatname">Common Ground Theatre</p>
                                <p className="app__show-theatname-sub">Educational Cultural Complex</p>
                            </div>
                        </td>
                        <td className="app__shows-dates">
                            <p>October 4th, 7:30pm PDT</p>
                            <p>October 5th, 7:30pm PDT</p>
                            <p>October 6th, 2:00pm PDT</p>
                            <br></br>
                            <p>October 11th, 7:30pm PDT</p>
                            <p>October 12th, 7:30pm PDT</p>
                            <p>October 13th, 2:00pm PDT</p>
                        </td>
                        <td className="app__shows-ticket-container">
                            <a className="app__shows-ticket" href="https://www.onthestage.tickets/show/common-ground-theatre/66f5e49b8271ba1a7021575c/tickets" target="_blank" rel="noopener noreferrer">Order Tickets</a>
                        </td>
                    </tr>
                </tbody>
            </table> 
            
        </div>
        
    </div>
);

export default UpcomingShows;